$(document).ready(function () {
    $(document)
        .bind('page.loading.start', function() {
            $('#loading_logo .logomiddle').addClass('logo-loading-animated');
        })
        .bind('page.loading.stop', function() {
            $('#loading_logo .logomiddle').removeClass('logo-loading-animated');
        })
        .bind("ajaxSend", function(){
            $(document).trigger('page.loading.start');
        })
        .bind("ajaxComplete", function(){
            $(document).trigger('page.loading.stop');
        });
});