require('pnotify/dist/umd/PNotify');

import * as PNotify from "pnotify/dist/umd/PNotify";
import * as PNotifyAnimate from "pnotify/dist/umd/PNotifyAnimate";

//PNotify.defaults.styling = 'morouna';
//PNotify.defaults.hide = false;
PNotify.defaults.minHeight = '54px';
PNotify.defaults.width = 'auto';
PNotify.defaults.titleTrusted = true;
PNotify.defaults.textTrusted = true;
PNotify.modules.Animate.defaults = {
    animate: true,
    inClass: 'bounceInDown',
    outClass: 'bounceOutUp'
};

var stackFlash = {
    dir1: 'down',
    firstpos1: 30,
    push: 'top',
    context: document.body
};

var addStackFlash = function(params) {
    params.stack = stackFlash;
    if ( params.type === 'success' ) {
        params.delay = 2000;
    }
    PNotify.alert(params)
};
window.stackFlash = stackFlash;
window.addStackFlash = addStackFlash;