var moment = require('moment');

$(document).ready(function () {
    $('body').tooltip({'selector': '[data-toggle="tooltip"]', 'placement': 'bottom'});

    $('.calendar').tooltip({'selector': '[data-toggle="tooltip"]', 'placement': 'bottom', 'template': '<div class="tooltip tooltip-white" role="tooltip"><div class="arrow big-arrow"></div><div class="tooltip-inner big-tooltip-inner"></div></div>'});

    $('.guide-tip').tooltip({'placement': 'bottom', 'template': '<div class="tooltip tooltip-white" role="tooltip"><div class="arrow big-arrow"></div><div class="tooltip-inner big-tooltip-inner"></div></div>'});

    $('.guide-link').on('click', function(e) { e.stopPropagation(); });

    $('div[data-widget]').each(function() {
        var $this = $(this);
        $this.load($this.data('widget-source'));
    });

    $('.input-government-id-mask').inputmask({regex: '[0-9]{2}/[0-9]{3}/[0-9]{3}/[0-9]{3}/[0-9]{3}', casing: 'upper'});

    var redrawWidgets = function () {
        $(".scalable-container").each(function () {
            var $el = $(this);
            var sidebar_width = 100;

            var max_width = parseInt($el.css('max-width')) - sidebar_width;
            var window_width = $(window).width() - sidebar_width;

            if (window_width < max_width) {
                $el.css({
                    transform: "scale(" + (window_width / max_width) + ")"
                });
            }
        });
    };

    redrawWidgets();

    $(window).scroll(function () {
        /*if ($(document).scrollTop() > 50) {
            $('#navbar_top').addClass('shrinked');
        } else {
            $('#navbar_top').removeClass('shrinked');
        }*/
    });

    $(window).resize(function () {
        redrawWidgets();
    });

    /* $('#navbar_left')
         .swipe(function (direction) {
             if (direction == 'right') {
                 $('#navbar_left').addClass('navbar-expand');
             } else {
                 $('#navbar_left').removeClass('navbar-expand');
             }
         }, {
             mouse: true,
             pen: true,
             distance: 50
         }); */

    // show-hide password widget function

    $('.password-showhide').append('<span class="password-showhide-button">Show</span>');

    $(document).on('click', '.password-showhide .password-showhide-button', function () {
        var text_show = $(this).attr('data-text-show') || 'Show';
        var text_hide = $(this).attr('data-text-hide') || 'Hide';

        $(this).text(
            $(this).text() === text_show ? text_hide : text_show
        );

        $(this).prev().attr('type', function (index, attr) {
            return attr === 'password' ? 'text' : 'password';
        });
    });

    $(document).on('click', 'table.dataTable tbody tr', function(event) {
        $('.btn-default', this).trigger('click');
    });

    $('[data-date-diff]').each(function(){
        $this = $(this);
        $this.text( moment.unix($this.data('date-diff')).fromNow() );
    });

    // dirty forms check
    $('form').not('[data-ignore-dirty]').areYouSure();

    // for bootstrap custom file input
    bsCustomFileInput.init();
});
