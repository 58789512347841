$(document).ready(function () {
    var reinitDatatable = function (scroll_height) {
        var $table = $('#payrolls_datatable');
        $table
            .dataTable({
                "destroy": true,
                "ajax": $table.data('url'),
                "order": [[ 1, "desc" ]],
                "columns": [
                    {
                        "data": null,
                        "width": "30%",
                        "render": function (data, type, row) {
                            var id = data.id;
                            var edit_prefix = $table.data('edit-url');
                            return data.name + "&nbsp;" +
                                '<span class="form-inline-edit form-inline-edit-right">' +
                                '<a class="btn btn-edit" data-target="modal_small" href="' + edit_prefix + '/' + id + '/edit">' +
                                '</a>' +
                                '</span>';
                        }
                    },
                    {
                        "data": null, "width": "30%",
                        "render": function (data, type, row) {
                            return formatter.dateIntervalColumn( data.datetime_from, data.datetime_to );
                        }
                    },
                    {
                        "data": null, "width": "20%",
                        "render": function (data, type, row) {
                            var id = data.id;
                            var edit_prefix = $table.data('edit-url');
                            return formatter.statusColumn( data.status ) + " &nbsp; " +
                                '<span class="form-inline-edit form-inline-edit-right">' +
                                '<a class="btn btn-delete" data-target="modal_inline" href="' + edit_prefix + '/' + id + '/delete">' +
                                '</a>' +
                                '</span>';
                        }
                    },
                    {
                        "data": null, "width": "20%",
                        "render": function (data, type, row) {
                            var id = data.id;
                            var edit_prefix = $table.data('edit-url');
                            return formatter.updatedAtColumn( data.updatedAt ) +
                                '<span class="form-inline-edit form-inline-edit-right">' +
                                '<a class="btn btn-view btn-default" data-target="modal_big" href="' + edit_prefix + '/' + id + '/view">' +
                                '</a> ' +
                                '<a class="btn btn-download" href="' + edit_prefix + '/' + id + '/download">' +
                                '</a> ' +
                                '</span>';
                        }
                    }
                ],
                "language": {
                    "search": "",
                    "searchPlaceholder": $table.data('search-placeholder'),
                    "emptyTable": $table.data('empty-table')
                },
                deferRender: true,
                scroller: true,
                scrollCollapse: true,
                scrollY: scroll_height,

                loadingIndicator: true,
                "paging": true,
            });
    };

    reinitDatatable($(window).height() - $('#navbar_top').outerHeight() - 180);
});