$(document).ready(function () {
    var reinitDatatable = function (scroll_height) {
        var $table = $('#payments_datatable');

        $table
            .dataTable({
                "destroy": true,
                "ajax": $table.data('url'),
                "order": [[ 1, "desc" ]],
                "columns": [
                    {"data": null, "width": "25%",
                        "render": function (data, type, row) {
                            return formatter.nameColumn(data.first_name, data.last_name) +
                                formatter.departmentColumn(data.department);
                        }
                    },
                    {"data": null, "width": "15%",
                        "render": function (data, type, row) {
                            return formatter.datetimeColumn( data.datetime );
                        }
                    },
                    {"data": null, "width": "15%",
                        "render": function (data, type, row) {
                            var id = data.id;
                            var edit_prefix = $table.data('edit-url');

                            return formatter.isAutoColumn( data.is_auto ) +
                                formatter.amountColumn( data.type_text, formatter.amountValue( data.amount ) )
                                + "&nbsp;" +
                                '<span class="form-inline-edit form-inline-edit-right">' +
                                '<a class="btn btn-edit btn-default" data-target="modal_big" href="' + edit_prefix + '/' + id + '/edit"></a>' +
                                '</span>';
                        }
                    },
                    {"data": "hours", "width": "10%"},
                    {"data": null, "width": "35%",
                        "render": function (data, type, row) {
                            var id = data.id;
                            var edit_prefix = $table.data('edit-url');
                            return "<span class='formatter-meaning'>" + data.meaning_text + "</span>" + data.note + "&nbsp;" +
                                '<span class="form-inline-edit form-inline-edit-right">' +
                                '<a class="btn btn-delete" data-target="modal_inline" href="' + edit_prefix + '/' + id + '/delete"></a>' +
                                '</span>';
                        }
                    }
                ],
                "language": {
                    "search": "",
                    "searchPlaceholder": $table.data('search-placeholder'),
                    "emptyTable": $table.data('empty-table')
                },
                deferRender: true,
                scroller: true,
                scrollCollapse: true,
                scrollY: scroll_height,

                loadingIndicator: true,
                "paging": true
            });
    };

    reinitDatatable($(window).height() - $('#navbar_top').outerHeight() - 180);
});