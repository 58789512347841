$(document).ready(function () {

  $('div[data-widget-type="chart"]').each(function() {
    var $this = $(this);
    $this.load($this.data('widget-source'));
  });

  $(document).on('submit', 'div[data-widget-type="chart"] form', function(e) {
    $container = $(this).parents('[data-widget-type]');
    $this_form = $('form', $container);

    var data = $this_form.serializeArray();

    $.ajax({
      type: "POST",
      cache: false,
      url: $this_form.attr('action'),
      data: data,
      success: function (data) {
        $container.html( data );
      }
    });
    return false;
  });

  // total earnings datatable
  var reinitDatatable = function (scroll_height) {
    var $table = $('#total_earnings_datatable');
    var cols_number = $('tr th', $table).length;
    $table
        .dataTable({
          "destroy": true,
          "ajax": $table.data('url'),
          "columns": [
            {
              "data": null,
              "width": "35%",
              "render": function (data, type, row) {
                return formatter.nameColumn(data.first_name, data.last_name);
              }
            },
            {
              "data": "salary",
              "width": "15%",
              "render": function (data, type, row) {
                return formatter.amountColumn( 'salary', data );
              }
            },
            {
              "data": "bonus",
              "width": "15%",
              "render": function (data, type, row) {
                return formatter.amountColumn( 'bonus', data );
              }
            },
            {
              "data": "penalty",
              "width": "15%",
              "render": function (data, type, row) {
                return formatter.amountColumn( 'penalty', data );
              }
            },
            {
              "data": "total",
              "width": "15%",
              "render": function (data, type, row) {
                return formatter.amountColumn( 'total', data );
              }
            }
          ],
          "language": {
            "search": "",
            "searchPlaceholder": $table.data('search-placeholder'),
            "emptyTable": $table.data('empty-table')
          },
          order: [[cols_number-1, 'desc']],
          searching: false,
          deferRender: true,
          scroller: true,
          scrollCollapse: true,
          scrollY: scroll_height,

          loadingIndicator: true,
          "paging": true,
        });
  };

  reinitDatatable($(window).height() - $('#navbar_top').outerHeight() - 180)

});