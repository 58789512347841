$(document).ready(function () {
    var reinitDatatable = function( scroll_height ) {
        var $table = $('#transactions_datatable');
        $table
            .dataTable({
                "destroy": true,
                "ajax": $table.data('url'),
                "order": [[ 0, "asc" ]],
                "columns": [
                    {
                        "data": null,
                        "render": function (data, type, row) {
                            return formatter.datetimeColumn( data.datetime );
                        }
                    },
                    {"data": null, "width": "20%",
                        "render": function (data, type, row) {
                            return formatter.amountValue( data.amount );
                        }
                    },
                    {"data": "note", "width": "50%"}
                ],
                deferRender: true,
                scroller: true,
                scrollCollapse: true,
                scrollY: scroll_height,
                loadingIndicator: true,
                "paging": true,
                searching: false,

                "language": {
                    "emptyTable": $table.data('empty-table')
                },
            });
    };

    reinitDatatable($(window).height() - $('#navbar_top').outerHeight() - 200);
});