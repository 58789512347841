const moment = require('moment');
const twix = require('twix');

//const moment = Twix.extend(Moment);

var amountValue = function(cents) {
    return cents;
};

var isAutoColumn = function(data_is_auto) {
    return '<span class="formatter-auto">' + (data_is_auto?'Auto':'') + '</span>';
};

var amountColumn = function(data_typename, data_amount) {
    if (data_amount !== 0 && !data_amount) {
        return '';
    }
    return '<span class="formatter-amount formatter-'+data_typename+'">' + data_amount + '</span>';
};

var typeColumn = function(data_typename) {
    return '<span class="formatter-type formatter-'+data_typename+'">' + data_typename + '</span>';
};

var typeAutoColumn = function(data_typename, data_is_auto) {
    return '<span class="formatter-type formatter-'+data_typename+'">' + data_typename +
        '</span><span class="formatter-auto">' + (data_is_auto?'Auto':'') + '</span>';
};

var nameColumn = function(data_first_name, data_last_name) {
    return '<span class="formatter-name">' + data_last_name + ' ' + data_first_name + '</span>'
};

var departmentColumn = function(data_department) {
    return '<span class="formatter-department">' + (data_department?data_department:'') + '</span>';
};

var statusColumn = function(data_status) {
    return '<span class="formatter-status formatter-status-'+data_status+'">' + data_status + '</span>';
};

var datetimeColumn = function( data_utc_datetime ) {
    var m = moment( data_utc_datetime );

    return '<span style="display:none">' + m.format('X') + '</span>' +
        '<span class="formatter-datetime-fromnow">' + m.fromNow() + '</span>' +
        '<span class="formatter-datetime">' + m.format('DD/MM/Y') + '</span>';
};

var updatedAtColumn = function( data_utc_datetime ) {
    var m = moment( data_utc_datetime );

    return '<span style="display:none">' + m.format('X') + '</span>' +
        '<span class="formatter-datetime">' + m.format('DD/MM/Y HH:mm') + '</span>';
};

var dateIntervalColumn = function( data_datetime_from, data_datetime_to ) {
    var from = moment( data_datetime_from );
    var range = from.twix(data_datetime_to, {
        allDay: true
    });

    return '<span style="display:none">' + from.format('X') + '</span>' +
        '<span class="formatter-datetime-fromnow">' + from.fromNow() + '</span>' +
        '<span class="formatter-datetime">' + range.format({monthFormat: "MMMM"}) + '</span>';
};

var periodColumn = function(data_period) {
    return '<span class="formatter-period">' + data_period + '</span>'
};

var numberWithCommas = function(value) {
    return Number(value).toLocaleString();
};

module.exports = {
    amountValue: amountValue,
    isAutoColumn: isAutoColumn,
    typeColumn: typeColumn,
    typeAutoColumn: typeAutoColumn,
    amountColumn: amountColumn,
    nameColumn: nameColumn,
    departmentColumn: departmentColumn,
    statusColumn: statusColumn,
    datetimeColumn: datetimeColumn,
    updatedAtColumn: updatedAtColumn,
    dateIntervalColumn: dateIntervalColumn,
    periodColumn: periodColumn,
    numberWithCommas: numberWithCommas
};