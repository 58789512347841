require('bootstrap');

$(document).ready(function () {

    $('.modal.blurred')
        .on('show.bs.modal', function () {
            //console.log('blurred!');
            $('#page_container')
                .removeClass('unblurred')
                .addClass('blurred');
        })
        .on('hidden.bs.modal', function () {
            //console.log('unblurred!');
            $('#page_container')
                .addClass('unblurred')
                .removeClass('blurred');
        });

    var _modal_show_handler = function() {
        // fix custom form plugins
        $('.modal-content .selectpicker').selectpicker();
        $('.modal-content .input-daterange').datepicker({format: 'yyyy-mm-dd'});
        $('.input-iban-mask').inputmask({regex: '[a-zA-Z]{2}[0-9]{2} ([a-zA-Z0-9]{4} ){5}', casing: 'upper'});
        $('.input-beneficiary-mask').inputmask({regex: '([0-9]{4} ){3}', casing: 'upper'});
        $('.password-showhide').append('<span class="password-showhide-button">Show</span>');
        // check dirty forms in modal
        $('.modal-content form').areYouSure();

        bsCustomFileInput.init();
    };

    var _modal_shown_handler = function() {
        // autofocus
        $('.modal-content input[autofocus]').focus();

        // fix datatables
        if($.fn.dataTable) {
            $('.modal-content .datatable').DataTable()
                .columns.adjust();
        }
    };

    var _is_modal_shown = function(modal) {
        var modalIsShown = (modal.data('bs.modal') || {})._isShown;
        return !(typeof modalIsShown === 'undefined' || !modalIsShown);
    };

    var _get_shown_modal = function() {
        var shown = false;
        $('.modal').each(function() {
            if( _is_modal_shown($(this)) ) {
                shown = $(this);
            }
        });
        return shown;
    };

    var _show_loaded_modal = function(modal_id) {
        var $modal = $('#'+modal_id);

        if( _is_modal_shown( $modal ) ) {
            $modal.modal('handleUpdate');
            _modal_show_handler();
        } else {
            if( $shown_modal = _get_shown_modal() ) {
                $shown_modal.one('hidden.bs.modal', function () {
                    $modal.modal('show');
                }).modal('hide');
            } else {
                $modal.modal('show');
            }
        }
    };

    $("#modal_small,#modal_big,#modal_large,#modal_restricted")
        .on('show.bs.modal', _modal_show_handler)
        .on('shown.bs.modal', _modal_shown_handler);

    $(document).on('click', 'a[data-target]', function(e) {
        e.preventDefault();

        var $button_position = $(this).offset();
        var $button_height = $(this).outerHeight();

        var target_modal_id = $(this).data('target');
        var dataURL = $(this).data('href') || $(this).attr('href');

        var return_target_modal_id = $(this).data('return-target');
        var returnURL = $(this).data('return-href');

        if( target_modal_id === 'modal_inline' ) {
            $modal_inline = $('#'+target_modal_id+'>div');

            var show_bs_modal_callback = function() {
                $modal_inline
                    .removeClass('animated')
                    .removeClass('zoomIn'); //css('opacity', 0);
            };

            var shown_bs_modal_callback = function () {
                $modal_inline.width($('.inline-modal-inner-wrapper',$modal_inline).outerWidth());

                var modal_inline_height = $modal_inline.height();
                var modal_inline_width = $modal_inline.width();

                var left = $button_position.left + $button_height / 2 - modal_inline_width / 2;

                var l = left - 124; // left panel + padding
                if( l < 0 ) {
                    left -= l;
                }

                var r = (left + modal_inline_width) - $(window).width();
                if( r > 0 ) {
                    left -= r + 24;
                }

                $modal_inline.css('top',
                    $button_position.top + $button_height / 2 - modal_inline_height / 2 + "px");

                $modal_inline.css('left', left + "px");

                $modal_inline
                    .addClass('animated')
                    .addClass('rubberBand');//$modal_inline.css('opacity', 1);
            };

            $('#'+target_modal_id)
                .on('show.bs.modal', show_bs_modal_callback)
                .on('shown.bs.modal', shown_bs_modal_callback)
                .on('hide.bs.modal', function() {
                    $('#'+target_modal_id)
                        .off('show.bs.modal', show_bs_modal_callback)
                        .off('shown.bs.modal', shown_bs_modal_callback);


                });
        }

        $('#'+target_modal_id+' .modal-content').load(dataURL,function(){
            _show_loaded_modal( target_modal_id );

            $('#'+target_modal_id)
                .one('hide.bs.modal', function() {
                    if (return_target_modal_id && returnURL) {
                        $('#' + return_target_modal_id + ' .modal-content').load(returnURL, function () {
                            _show_loaded_modal(return_target_modal_id);
                        });
                    }
                })
                .on('hide.bs.modal', function(e) {
                    var modal_form = $('#'+target_modal_id+' form');
                    if (modal_form.hasClass('dirty')) {
                        if (confirm("Are you sure to discard unsaved data?")) {
                            modal_form.trigger('reinitialize.areYouSure');
                        } else {
                            e.preventDefault();
                            e.stopImmediatePropagation();
                        }
                    }
                });
        });

        return false;
    });

    $(document).on('submit', 'div[data-ajax="true"] form', function(event) {
        $container = $(this).parent().parent();
        $this_form = $('form', $container);

        var data = new FormData($this_form[0]);
        //console.log(data);

        $.ajax({
            type: "POST",
            cache: false,
            url: $this_form.attr('action'),
            data: data,
            contentType: false,
            processData: false,
            success: function (data) {
                $container.html( data );
                _modal_show_handler();
            }
        });
        return false;
    });

    $(document).on('click', 'form.form-inline-switch', function(event) {
        event.stopPropagation();
    });

    $(document).on('change', 'form.form-inline-switch input[type="checkbox"]', function(event) {
        var $this_input = $(this);
        var $container = $this_input.parent().parent().parent();
        var $this_form = $('form', $container);

        var data = $this_form.serializeArray();
        console.log(data);

        var animate = function($element, animation) {
            $element
                .addClass('animated')
                .addClass('faster')
                .addClass(animation)
                .on('animationend', function() {
                    $element
                        .removeClass('animated')
                        .removeClass('faster')
                        .removeClass(animation);
                });
        };

        $.ajax({
            type: "POST",
            cache: false,
            url: $this_form.attr('action'),
            data: data,
            success: function (data) {
                if(data.success) {
                    animate($this_form, 'bounceIn');
                } else {
                    animate($this_form, 'shake');
                }
                $this_input.prop('checked', data.checked);
            },
            error: function (data) {
                $this_input.prop('checked', !($this_input.prop('checked')));
                animate($this_form, 'shake');
            }
        });

        return false;
    });

});

var shakeIt = function( animation ) {
    $('.modal .modal-content')
        .addClass('animated')
        .addClass('faster')
        .addClass( animation )
        .on('animationend', function() {
            $('.modal .modal-content')
                .removeClass('animated')
                .removeClass('faster')
                .removeClass(animation);
/*
            NOT used now
            TODO: fix z-index issue when '.animated' item

            var $invalid_items = [];
            $('.modal .modal-content .is-invalid').each(function() {
                $invalid_items.push($(this));
            });

            var shakeNext = function(index, $items_list) {
                var $this_invalid_item = $items_list[index];
                var $next_invalid_item = $items_list[index+1];

                $this_invalid_item.addClass('animated')
                    .addClass('faster')
                    .addClass( 'flash' )
                    .on('animationend', function() {
                        $this_invalid_item
                            .removeClass('animated')
                            .removeClass('flash')
                            .removeClass('heartBeat');

                        if( $next_invalid_item ) {
                            shakeNext(index+1, $items_list)
                        }
                    });
            };

            shakeNext(0, $invalid_items);*/
        });
};

window.shakeIt = shakeIt;

var hideAllModals = function() {
    $('.modal').modal('hide');
};

var reloadDataTable = function () {
    $dt = $('.datatable-wrapper table:not(.DTFC_Cloned)');
    if( $dt ) {
        $dt.DataTable().ajax.reload(null, false);
    }
};

window.hideAllModals = hideAllModals;
window.reloadDataTable = reloadDataTable;