require('../css/app.scss');

const $ = require('jquery');
window.$ = $;
// const jQuery = $;
// window.jQuery = $;
// global.$ = global.jQuery = $;

const moment = require('moment');
window.moment = moment;

require('./vendor/jquery.mobile.swipe.js');
require('./vendor/jquery.guide.js');
require('./vendor/jquery.are-you-sure');
require('popper.js');
require('bootstrap');
require('bootstrap-select');
require('bootstrap-datepicker');

const bsCustomFileInput = require('bs-custom-file-input');
window.bsCustomFileInput = bsCustomFileInput;

require('js-year-calendar');

require('datatables.net');
require('datatables.net-bs4');
require('datatables.net-scroller-bs4');
require('datatables.net-fixedcolumns-dt');
require('datatables.net-buttons');

require('chart.js');
require('chartjs-plugin-style');

import GSTC from 'gantt-schedule-timeline-calendar/dist/index.umd';
window.GSTC = GSTC;

import CalendarScroll from "gantt-schedule-timeline-calendar/dist/CalendarScroll.plugin"
window.CalendarScroll = CalendarScroll;

import WeekendHighlight from "gantt-schedule-timeline-calendar/dist/WeekendHighlight.plugin"
window.WeekendHighlight = WeekendHighlight;

const formatter = require('./tools/formatter.js');
window.formatter = formatter;

require('inputmask/dist/inputmask/jquery.inputmask');

require('./modules/loading.js');

require('./pnotify.js');
require('./functions.js');
require('./modules/modals.js');

require('./datatables-init.js');

require('./pages/charts.js');
require('./pages/contracts.js');
require('./pages/dashboard.js');
require('./pages/employees.js');
require('./pages/departments.js');
require('./pages/payments.js');
require('./pages/payrolls.js');
require('./pages/settings.js');